/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import "bootstrap/scss/bootstrap";
@import "bootstrap/dist/css/bootstrap.min.css";

@import "./app/styles/paper-kit/variables";
@import "./app/styles/paper-kit/mixins";

@import "./app/styles/paper-kit/typography";

// Core CSS
@import "./app/styles/paper-kit/misc";
@import "./app/styles/paper-kit/buttons";
@import "./app/styles/paper-kit/inputs";
@import "./app/styles/paper-kit/progress-bars";
@import "./app/styles/paper-kit/sliders";
@import "./app/styles/paper-kit/alerts";
@import "./app/styles/paper-kit/labels";
@import "./app/styles/paper-kit/tooltips-and-popovers";
@import "./app/styles/paper-kit/sections";
@import "./app/styles/paper-kit/checkbox-radio";
@import "./app/styles/paper-kit/navbars";
@import "./app/styles/paper-kit/images";
@import "./app/styles/paper-kit/cards";
@import "./app/styles/paper-kit/footers";

// Bootstrap Switch
@import "./app/styles/paper-kit/bootstrap-switch";

// Fancy Stuff
@import "./app/styles/paper-kit/dropdown";
//
// icons
@import "./app/styles/paper-kit/icons";
//
@import "./app/styles/paper-kit/tabs-navs-pagination";
@import "./app/styles/paper-kit/collapse";
@import "./app/styles/paper-kit/carousel";
@import "./app/styles/paper-kit/modal";
//
@import "./app/styles/paper-kit/responsive";
@import "./app/styles/paper-kit/plugins/datetimepicker";
@import "./app/styles/paper-kit/social-buttons";
@import "./app/styles/paper-kit/examples";
//
@import "./app/styles/nucleo-icons.css";
@import "./app/styles/paper-kit-2.css";

@font-face {
  font-family: "nucleo-icons";
  src: url("assets/fonts/nucleo-icons.eot");
  src: url("assets/fonts/nucleo-icons.eot") format("embedded-opentype"),
    url("assets/fonts/nucleo-icons.woff2") format("woff2"),
    url("assets/fonts/nucleo-icons.woff") format("woff"),
    url("assets/fonts/nucleo-icons.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
